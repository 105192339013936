@charset "utf-8";
@import "mixin";

.sp{
  display: none;
  @include sp(){
    display: block;
  }
}
.pc{
  display: inherit;
  @include sp(){
    display: none;
  }
}
/* width
------------------------------ */
w-100per {
  width: 100%;
}

/* height
------------------------------ */
h-a {
  height: auto;
}

/* font
------------------------------ */
.mincho {
  font-family: Meiryo, serif;
}

/* margin
------------------------------ */
.mlr-a {
  margin-left: auto;
  margin-right: auto;
}

.ml-a {
  margin-left: auto;
}

.mr-a {
  margin-right: auto;
}

.mt0 {
  margin-top: 0px;
}

.mt1 {
  margin-top: 1px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt70 {
  margin-top: 70px;
}

.mt80 {
  margin-top: 80px;
}

.mt90 {
  margin-top: 90px;
}

.mt100 {
  margin-top: 100px;
}

.ml1 {
  margin-left: 1px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.ml50 {
  margin-left: 50px;
}

.ml60 {
  margin-left: 60px;
}

.ml65 {
  margin-left: 65px;
}

.ml70 {
  margin-left: 70px;
}

.ml80 {
  margin-left: 80px;
}

.ml90 {
  margin-left: 90px;
}

.ml100 {
  margin-left: 100px;
}

.mr1 {
  margin-right: 1px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mr35 {
  margin-right: 35px;
}

.mr40 {
  margin-right: 40px;
}

.mr50 {
  margin-right: 50px;
}

.mr60 {
  margin-right: 60px;
}

.mr70 {
  margin-right: 70px;
}

.mr80 {
  margin-right: 80px;
}

.mr90 {
  margin-right: 90px;
}

.mr100 {
  margin-right: 100px;
}

.mb1 {
  margin-bottom: 1px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb100 {
  margin-bottom: 100px;
}

.mb130 {
  margin-bottom: 130px;
}

.mb140 {
  margin-bottom: 140px;
}

.mb160 {
  margin-bottom: 160px;
}

/* padding
------------------------------ */
.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt30 {
  padding-top: 30px;
}

.pt40 {
  padding-top: 40px;
}

.pt50 {
  padding-top: 50px;
}

.pt60 {
  padding-top: 60px;
}

.pt65 {
  padding-top: 65px;
}

.pt70 {
  padding-top: 70px;
}

.pt80 {
  padding-top: 80px;
}

.pt90 {
  padding-top: 90px;
}

.pt100 {
  padding-top: 100px;
}

.pt120 {
  padding-top: 120px;
}

.pt130 {
  padding-top: 130px;
}

.pt170 {
  padding-top: 170px;
}

.pt350 {
  padding-top: 350px;
}

.pl5 {
  padding-left: 5px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pl35 {
  padding-left: 35px;
}

.pl40 {
  padding-left: 40px;
}

.pl45 {
  padding-left: 45px;
}

.pl50 {
  padding-left: 50px;
}

.pl60 {
  padding-left: 60px;
}

.pl70 {
  padding-left: 70px;
}

.pl80 {
  padding-left: 80px;
}

.pl90 {
  padding-left: 90px;
}

.pl100 {
  padding-left: 100px;
}

.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

.pr40 {
  padding-right: 40px;
}

.pr50 {
  padding-right: 50px;
}

.pr60 {
  padding-right: 60px;
}

.pr70 {
  padding-right: 70px;
}

.pr80 {
  padding-right: 80px;
}

.pr90 {
  padding-right: 90px;
}

.pr100 {
  padding-right: 100px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb35 {
  padding-bottom: 35px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb60 {
  padding-bottom: 60px;
}

.pb70 {
  padding-bottom: 70px;
}

.pb80 {
  padding-bottom: 80px;
}

.pb90 {
  padding-bottom: 90px;
}

.pb100 {
  padding-bottom: 100px;
}

.pb140 {
  padding-bottom: 140px;
}

.pb160 {
  padding-bottom: 160px;
}

.pb180 {
  padding-bottom: 180px;
}

.pb190 {
  padding-bottom: 190px;
}

.pb260 {
  padding-bottom: 260px;
}

.pb350 {
  padding-bottom: 350px;
}

/* float
------------------------------ */
.fl {
  float: left
}

.fr {
  float: right;
}

/* display
------------------------------ */
.d-b {
  display: block;
}

.d-f {
  @include df();
}

.d-f-fs-fs {
  @include df("fs", "fs");
}

.d-f-fs-sb {
  @include df("fs", "sb");
}

.flex-col {
  flex-direction: column;
}

/* letter-spacing
------------------------------ */
.ls-1 {
  letter-spacing: -1px;
}

.ls-2 {
  letter-spacing: -2px;
}

.ls1 {
  letter-spacing: 1px;
}

/* text-align
------------------------------ */
.ta-c {
  text-align: center;
}

.ta-l {
  text-align: left;
}

.ta-r {
  text-align: right;
}

.f-white {
  color: white;
}
