$breakpoints: (
  'sm': 'screen and (max-width: 400px)',
  'md': 'screen and (max-width: 768px)',
  'lg': 'screen and (max-width: 1000px)',
  'xl': 'screen and (max-width: 1200px)',
) !default;

@mixin sp($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin ie(){
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
    @content;
  }
}
@mixin o-font($fs){
  @media (orientation: landscape){
    font-size: $fs + px;
  }
  @media (orientation: portrait){
    font-size: ($fs / 375 * 100) + vw;
  }
}

//////////////////////////////////////
// <align-items>
//  flex-start = to
//  center = ce
//  flex-end = bo
//
// <justify-content>
//  flex-start = le
//  center = ce
//  flex-end = ri
//  space-between = sb
//  space-around = sa
//////////////////////////////////////
@mixin df($ai: ce, $jc:ce) {
  display: flex;
  @if $ai=='ce' {
    align-items: center;
  }
  @else if $ai=='fs' {
    align-items: flex-start;
  }
  @else if $ai=='fe' {
    align-items: flex-end;
  }
  @else if $ai=='st' {
    align-items: stretch;
  }
  @if $jc=='ce' {
    justify-content: center;
  }
  @else if $jc=='fs' {
    justify-content: flex-start;
  }
  @else if $jc=='fe' {
    justify-content: flex-end;
  }
  @else if $jc=='sa' {
    justify-content: space-around;
  }
    @else if $jc=='sb' {
    justify-content: space-between;
  }
  @else if $jc=='se' {
    justify-content: space-evenly;
  }
  @else {
    justify-content: center;
  }
}
@mixin idf($ai: ce, $jc:ce) {
  display: flex;
  @if $ai=='ce' {
    align-items: center;
  }
  @else if $ai=='fs' {
    align-items: flex-start;
  }
  @else if $ai=='fe' {
    align-items: flex-end;
  }
  @else if $ai=='st' {
    align-items: stretch;
  }
  @if $jc=='ce' {
    justify-content: center;
  }
  @else if $jc=='fs' {
    justify-content: flex-start;
  }
  @else if $jc=='fe' {
    justify-content: flex-end;
  }
  @else if $jc=='sa' {
    justify-content: space-around;
  }
    @else if $jc=='sb' {
    justify-content: space-between;
  }
  @else {
    justify-content: center;
  }
}
